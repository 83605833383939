<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-subtitle> Translado </v-card-subtitle>
    <v-card-text>
      <v-row class="mb-1">
        <v-col cols="12">
       
        </v-col>
      </v-row>
      <v-divider> </v-divider>
    </v-card-text>
    
    <v-divider></v-divider>
    
    
     
  </v-card>
</template>
 
<script>
import { ref } from '@vue/composition-api'
 
export default {
  components: {
     
  },
 
  setup(props, context) {
     

    return { 
    }
  },
}
</script>